/* o import abaixo é para exibir os ícones do bootstrap */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

/* o div inicial de uma página é sempre esse */
.App {
  background-color: #d4d4b6;
  min-height: 100vh;
  border: 3px solid #044d0e;
/* estas 2 propriedades visam limitar o tamanho horizontal e centralizar o app, porém ao ativá-los os botões de boltar e home
   ficam desposicionados; pensar melhor em como resolver isso 
  max-width: 30em;
  margin: auto;
*/
}

/* esse div é o que vem na sequencia do div App,  envolve todas as páginas */
.App-body {
  padding: 10px;
}

/* estilos usado para o topo das páginas */
.App-header {
  background-color: #044d0e;
  color: white;
  font-size: 1.5em;
  text-align: center;
}

.bt-top {
  color: white;
  top: 15px;
  position: absolute;
}

.TitCabPag {
  font-weight: bolder;
}

.SubTitCabPag {
  background-color: #198828;
  font-size: 0.9em;
}

.SubTitCabPagYellow {
  background-color: yellow;
  color: #044d0e;
  font-size: 0.9em;
}

.SubTitRel {
  background-color: #198828;
  font-size: 0.7em;
}

/* estilos usados na página de login */
.App-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.login-form {
  padding: 5px;
}

.form-error {
  display: block;
  color: red;
  font-size: 0.6em;
  font-weight: 600;
  position: absolute;
  top: 6vmin;
  left: 1vmin;
}

/* estilos usados para posicionar elementos */
.to-left {
  position: absolute;
  left: 10px;
}

.to-right {
  position: absolute;
  right: 10px;
}

.to-top {
  position: absolute;
  top: 5px;
}

/* estilos de alinhamento simples */
.pagination {
  margin: auto;
  width: 50%;
  padding-top: 10px;
}

/* estilos tela de vendas - clientes */
.listgroup {
  height: 67vh;
  overflow-y: scroll;
  overflow-x: hidden; 
}

/* estilos tela de vendas - produtos */
.ql {
  height: 5px;
}

/* botoes da tela de vendas e movimentações de estoque */
.btn-prod, .btn-unid, .btn-trans {
  list-style-type: none;
  padding-left: 1em;
}

.btn-prod li, .btn-unid li, .btn-trans li {
  float: left;
  margin: 0 0.3em 0.3em 0;
  position: relative;
  border-radius: 5px;
}

.btn-prod li {
  background: rgb(162, 204, 165);
  width: 3.9em;
  height: 2.5em;
}

.btn-unid li {
  background: rgb(134, 185, 15);
  width: 3.9em;
  height: 2.7em;
}

.btn-trans li {
  background: rgb(141, 204, 233);
  width: 3.9em;
  height: 2.5em;
}

.btn-prod label,  .btn-prod input,
.btn-unid label,  .btn-unid input,
.btn-trans label, .btn-trans input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-prod input[type="radio"], 
.btn-unid input[type="radio"],
.btn-trans input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.btn-prod input[type="radio"]:checked+label,
.btn-unid input[type="radio"]:checked+label,
.btn-trans input[type="radio"]:checked+label,
.Checked+label {
  background: rgb(207, 172, 76);;
}

.btn-prod label, 
.btn-unid label,
.btn-trans label {
  padding: 0.3em;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 90;
  border: 0px solid white;
  font-weight: 600;
}

.btn-prod label {
  font-size: 0.7em;
}

.btn-unid label {
  font-size: 0.8em;
}

.btn-trans label {
  font-size: 0.8em;
}

.carrinho {
  background-color: hsla(116, 27%, 69%, 0.589);
  font-size: 0.9em;
}

/* texto destacado */
.txt_destac {
  font-size: 1.2em
}

/* estilos de tabelas em geral */
thead {
  font-size: 0.8em;
  background: rgb(134, 185, 15);
}

tbody {
  font-size: 0.8em; 
  background: rgb(204, 212, 187);
}

.separador {
  border-top: 2px solid rgb(31, 138, 10);
  margin-top: 5px;
  margin-bottom: 5px;
}

.label {
  font-size: 1.2em;
  font-weight: bold;
  color: #0f4b17;
}

.sTabs {
  background-color: rgb(202, 202, 202);
  padding-top: 1pt;
  background-color: #c0c093;
}

.sTab {
  font-size: 0.8em;
  font-weight: bold;
}

/* versão do sistema */
.sVersao {
  font-size: 9pt;
  color: #0f4b17;
  text-align: center;
  font-weight: bold;
}

/* usuário logado */
.sUserLog {
  font-size: 12pt;
  color: #0f4b17;
  text-align: center;
  font-weight: bold;
}

/* fonte 7 para instruções sobre datas, outras instruções e razão social do cliente que aparece na tela de vendas */
.sFonte7Verde {
  font-size: 7pt;
  color: #0f4b17;
  text-align: center;
  font-weight: bold;
}

/* fonte 7 para instruções sobre datas, outras instruções e razão social do cliente que aparece na tela de vendas */
.sFonte6Branco {
  font-size: 7pt;
  color: #ffffff;
  text-align: center;
}

/* valor em atraso do cliente que aparece na tela de vendas */
.sAtraso {
  font-size: 11pt;
  color: #eb0d0d;
  text-align: center;
  font-weight: bold;
}

/* razao social na lista de clientes */
.sRazaoTelCli {
  font-size: 0.9em;
  color: #0f4b17;
}

/* tamanho do box para seleção de clientes */
.sSizeBoxCliente {
  height: calc(10rem + 2px) !important;
}